import Teaser from './pages/Teaser/Teaser';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService/TermsOfService';

/**
 * List of all application route names.
 */
export enum RouteNames {
  HOME = '/',
  TEASER = '/', // Temporary
  ABOUT_US = 'about-us',
  TERMS_OF_SERVICE = 'terms',
  PRIVACY_POLICY = 'privacy',
};

/**
 * Route configurations for application.
 */
export const routes = [{
  name: 'Coming Soon',
  route: '',
  index: true,
  component: <Teaser />,
  exact: true,
},{
  name: 'Terms of Service',
  route: RouteNames.TERMS_OF_SERVICE,
  component: <TermsOfService />,
}, {
  name: 'Privacy Policy',
  route: RouteNames.PRIVACY_POLICY,
  component: <PrivacyPolicy />,
} ];

