import { Outlet } from 'react-router-dom';
import GlobalFooter from '../../organisms/GlobalFooter/GlobalFooter';
import GlobalNav from '../../organisms/GlobalNav/GlobalNav';

/**
 * Template which ensures route content is injected between header and footer of application.
 * @returns Layout with nav and footer. Output sandwiched between
 */
export const Layout = () => (
  <>
    <GlobalNav />
    <Outlet />
    <GlobalFooter />
  </>
);

export default Layout;