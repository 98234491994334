import { Link } from 'react-router-dom';
import { RouteNames } from '../../../routes';
import Icon from '../../atoms/Icon/Icon';
import './GlobalFooter.scss';

export const GlobalFooter = () => {
  return (
    <div className="global-footer p-4">
      <div className="flex flex-col justify-center text-center">
        <div id="contact-us">
          <div className="contact">
            <a
              href="https://www.linkedin.com/company/minnekind/about"
              className="m-2"
              aria-label='Link to Minnekind LinkedIn About Page.'
              tabIndex={0}>
              <Icon iconName="linkedin" size="3xl" />
            </a>
            <a
              href="mailto:info@minnekind.com"
              className="m-2"
              aria-label='Link to Minnekind Email.'
              tabIndex={0}>
              <Icon iconName="envelope" size="3xl" />
            </a>
          </div>
          <div className="contact">
            <a
              href="https://instagram.com/minnekind?igshid=YmMyMTA2M2Y="
              className="m-2"
              aria-label='Link to Minnekind Instagram Page.'
              tabIndex={0}>
              <Icon iconName="instagram" size="3xl" />
            </a>
            <a
              href="https://twitter.com/minnekind"
              className="m-2"
              aria-label='Link to Minnekind Twitter Page.'
              tabIndex={0}>
              <Icon iconName="twitter" size="3xl" />
            </a>
          </div>
        </div>

        <br />
        <div tabIndex={0}>
          6248 Lakeland Ave N, Suite 208
          <br />
          Minneapolis, MN 55428
        </div>

        <br />
        <div tabIndex={0}>
          &copy;2022 Minnekind Health Services, LLC
        </div>
        <div id="terms-and-privacy-links">
          <Link to={RouteNames.TERMS_OF_SERVICE}><span>Terms and Conditions</span></Link> | <Link to={RouteNames.PRIVACY_POLICY}><span>Privacy Policy</span></Link>
        </div>
      </div>
    </div>
  );
};

export default GlobalFooter;
