import React from 'react';
import './Teaser.scss';

const Teaser = () => {
  return (
    <div className="App">
      <header className="App-header">
        <img src="/MinnekindLogo.svg" tabIndex={0} className="App-logo" alt="Animated Minnekind Logo" width="70%" />
        <p className="max-w-prose" tabIndex={0}>
          Minnekind Web Experience coming Fall 2022
        </p>
      </header>
    </div>
  );
}

export default Teaser;
