import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './styles/index.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import reportWebVitals from './utilities/reportWebVitals';
import { routes } from './routes';
import Layout from './components/templates/Layout/Layout';
import { AwsRum, AwsRumConfig } from 'aws-rum-web';

const rootElement = document.getElementById('root') as HTMLElement;

try {
  const config: AwsRumConfig = {
    sessionSampleRate: 1,
    guestRoleArn: "arn:aws:iam::406451428462:role/RUM-Monitor-us-east-1-406451428462-7309650077661-Unauth",
    identityPoolId: "us-east-1:1a9a65e5-f140-40eb-a17a-175d49036b40",
    endpoint: "https://dataplane.rum.us-east-1.amazonaws.com",
    telemetries: ["performance","errors","http"],
    allowCookies: true,
    enableXRay: false
  };

  const APPLICATION_ID: string = '276f3835-9852-4b54-a8a6-a63db6e750ae';
  const APPLICATION_VERSION: string = '1.0.0';
  const APPLICATION_REGION: string = 'us-east-1';

  new AwsRum(
    APPLICATION_ID,
    APPLICATION_VERSION,
    APPLICATION_REGION,
    config
  );
} catch (error) {
  // TODO: Report errors in cloudwatch rum initialization.
}

const App = () => (
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          {routes.map((route) =>
            <Route
              path={route.route}
              element={route.component}
              key={route.name}
              index={route.index || false}
            />)}
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

if(rootElement.hasChildNodes()) {
  ReactDOM.hydrateRoot(rootElement, App());
} else {
  ReactDOM.createRoot(rootElement).render(App());
}





// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
