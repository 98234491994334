import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import Button from 'react-bootstrap/Button';
import { RouteNames } from '../../../routes';
import './GlobalNav.scss';

export const GlobalNav = () => {
  const contactUs = () => {
    document.querySelectorAll('#contact-us div.contact').forEach(contactUsDiv => {
      contactUsDiv.classList.add('contact-us');
      setTimeout(() => {
        contactUsDiv.classList.remove('contact-us');
      }, 2100);
    });
  };

  return (
    <>
      <nav className="global-nav navbar">
        <div className="container-fluid p-4 flex">
          <Link to={RouteNames.HOME} className="navbar-brand flex">
            <img src="/MinnekindLogo.svg" alt="Minnekind Logo: Link to Homepage" width={200} />
            <div className="leading-2 ml-2 pl-2 -pb-2 motto">
              Always Kind
              <br />
              Every Time
            </div>
            <div className="motto-sm"> Always Kind. Every Time.</div>
          </Link>

          <HashLink to="#contact-us">
            <Button onClick={contactUs}>Contact Us</Button>
          </HashLink>
        </div>
      </nav>
    </>
  );
};

export default GlobalNav;