import { CSSProperties } from 'react';
import { IconName, IconNameType } from './IconName';

export type IconSize =
  'xs' | 'sm' | 'base' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl' | '6xl' | '7xl' | '8xl' | '9xl';

export interface FontIconProps {
  iconName: IconName | IconNameType;
  style?: CSSProperties;
  size?: IconSize;
};

export const FontIcon = ({ style, iconName, size }: FontIconProps) => (
  <i className={`bi bi-${iconName}` + (size ? ` text-${size}` : '')} style={style}/>
);

export default FontIcon;
